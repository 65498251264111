.fusionFileSelectContents {
  font-size: 1rem;
}

.fusionFileSelectContentsSection {
  padding-bottom: 1rem;
}

.fusionFileSelectContentsSectionTitle {
  font-size: 1.1rem;
}

.fusionFileSelectContentsSectionRow {
  padding-bottom: 1rem;
}

.fusionFileSelectContentsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}