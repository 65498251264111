.aboutPage {
  padding: 1rem;
  font-size: 1.1rem;
  width: 40rem;
  max-width: 95%;
  margin: 0 auto;
}

.aboutPageTitle {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1rem;
}

.aboutPageButtons {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}


.aboutPageImageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.aboutPageImage {
  height: 200px;
  width: 200px;
  max-width: 100%;
  object-fit: cover;
}

.aboutPageCleanupImage {
  width: 150px;
  max-width: 100%;
  object-fit:cover;
}

