.cleanerFileControlSection {
  padding-bottom: 1rem;
}

.cleanerFileControlWarning {
  color: red;
  font-weight: bold;
}

.cleanerFileControlButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
