.objectImageItem {
  position: relative;
}

.objectImageItemImage {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.objectImageItemCheck {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: var(--background-color);
  font-size: 35px;
  opacity: 0.5;
}

.objectImageItemOther {
  height: 100px;
  width: 100px; 
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  box-shadow: var(--shadow-medium);
  border: 0.25px solid gray;
  border-radius: 2px;
}

.objectImageItemFileMark {
  position: absolute;
  top: 5px;
  left: 7.5px;
  font-size: 30px;
  /* padding-left: 5px; */
}

.objectImageItemOtherCheck {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: var(--background-color);
  font-size: 35px;
  opacity: 0.5;
}

.objectImageItemOtherName {
  position: absolute;
  top: 50px;
  /* bottom: 5px; */
  right: 1px;
  left: 1px;
  max-height: 50px;
  overflow: hidden;
  font-size: 16px;
}


@media (min-width: 480px) {
  .objectImageItemImage {
    height: 100px;
    width: auto;
    object-fit: unset;
  }
}