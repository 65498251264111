.imageFusion {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    padding: 1rem;
}

.fusionSectoin {
    padding-bottom: 1rem;
}

.fusionInputButtons {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
}

.fusionInputImages {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

.fusionInputImage {
    max-height: 150px;
    max-width: 100%;
}

.fusionImageDeminsions {
    font-size: 0.8rem;
}

.fusionInfoMark {
    text-align: right;
    cursor: pointer;
}

.fusionInfoTitle {
    font-size: 1.5rem;
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
}
.fusionExampleImages {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}

.fusionActionButtons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
}

.fusionParamInputs {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
}

.fusionParamRow {
    padding-bottom: 0.5rem;
}

.fusionProgressCycle {
    position: fixed;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 1.5rem;
    background-color: var(--color-gray-100);
    opacity: 0.7;
}

.fusionProgressServerNotify {
    position: fixed;
    top: 70px;
    left: 10px;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: var(--color-gray-100);
    opacity: 0.7;
}
