.imageCombiner {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    padding: 1rem;
}

.combinerSectoin {
    padding-bottom: 1rem;
}

.combinerInputButtons {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
}

.combinerInputImages {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

.combinerInputImage {
    max-height: 150px;
    max-width: 100%;
}

.combinerImageDeminsions {
    font-size: 0.8rem;
}

.combinerInfoMark {
    text-align: right;
    cursor: pointer;
}

.combinerInfoTitle {
    font-size: 1.5rem;
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
}
.combinerExampleImages {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}

.combinerActionButtons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
}

.combinerFormatSelect {
    padding: 0.5rem;
}