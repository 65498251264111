.convertFileSelectContents {
  font-size: 1rem;
}

.convertFileSelectContentsSection {
  padding-bottom: 1rem;
}

.convertFileSelectContentsSectionTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.convertFileSelectContentsSectionRow {
  padding-bottom: 1rem;
}

.convertFileSelectContentsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.convertFileSelectedFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1rem;
}