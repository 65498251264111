.resizerFileSelectContents {
  font-size: 1rem;
}

.resizerFileSelectContentsSection {
  padding-bottom: 1rem;
}

.resizerFileSelectContentsSectionTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.resizerFileSelectContentsSectionRow {
  padding-bottom: 1rem;
}

.resizerFileSelectContentsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.resizerFileSelectedFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1rem;
}