.imageSizeControl {
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  align-items: center;
  padding-right: 1rem;

}

.imgeSizeControlMobButton {
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}

.imgeSizeControlButton {
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}