@use '../../styles/Mixins/' as *;

.btn-primary {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  background-color: var(--page-bg);
  color: var(--btn-text-color);
  width: max-content;
  padding: 0.5rem;
  place-items: center;
  border-radius: 0.5rem;
  z-index: 1;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--btn-primary-hover-bg);
    color: var(--btn-text-hover-color);
  }

  svg {
    width: 20px;
    height: auto;
  }
}

.btn-primary-disabled {
  user-select: none;
  background-color: var(--page-bg);
  pointer-events: none;
  opacity: 0.5;
}

.btn-border {
  border-color: var(--btn-border-color);
  border-width: 1px;
  border-style: solid;
}
