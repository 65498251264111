@use '../../styles/Mixins' as *;

.editor-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.react-transform-wrapper {
  display: grid !important;
  width: 100% !important;
  height: 100% !important;
}

.editor-canvas-container {
  display: grid;
  grid-template-areas: 'editor-content';
  row-gap: 1rem;
}

.editor-canvas {
  grid-area: editor-content;
  z-index: 2;
}

.original-image-container {
  grid-area: editor-content;
  pointer-events: none;
  display: grid;
  grid-template-areas: 'original-image-content';

  img {
    grid-area: original-image-content;
  }

  .editor-slider {
    grid-area: original-image-content;
    height: 100%;
    width: 6px;
    justify-self: end;
    background-color: var(--yellow-accent);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2;
  }
}

.editor-canvas-loading {
  pointer-events: none;
  animation: pulsing 750ms infinite;
}

.editor-toolkit-panel {
  position: fixed;
  // bottom: 0.5rem;
  bottom: 8%;
  border-radius: 3rem;
  padding: 0.4rem 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(12px);
  background-color: var(--page-bg-light);
  animation: slideUp 0.2s ease-out;
  border: var(--editor-toolkit-panel-border);
  box-shadow: 0 0 0 1px #0000001a, 0 3px 16px #00000014, 0 2px 6px 1px #00000017;

  @include mobile {
    padding: 1rem 2rem;
    grid-template-areas:
      'toolkit-size-selector toolkit-size-selector'
      'toolkit-brush-slider toolkit-brush-slider'
      'toolkit-btns toolkit-btns';
    row-gap: 2rem;
    justify-items: center;
  }

  .eyeicon-active {
    background-color: var(--yellow-accent);
    color: var(--btn-text-hover-color);
  }
}

.editor-brush-slider {
  grid-area: toolkit-brush-slider;
  user-select: none;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  height: max-content;
  column-gap: 1rem;
  align-items: center;

  @include slider-bar;
}

.editor-toolkit-btns {
  display: flex;
  gap: 12px;
}

.brush-shape {
  position: absolute;
  border-radius: 50%;
  background-color: #ffcc00bb;
  border: 1px solid var(--yellow-accent);
  pointer-events: none;
}
