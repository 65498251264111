.imageResizer {
  width: 100%;
  max-width: 40rem;
  padding: 1rem;
  margin: 0 auto;
}

.imageResizeElement {
  padding-bottom: 1rem;
}

.imageResizerPreview {
  max-height: 200px; 
  max-width: 100%;
}


.imageResizerInputButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
