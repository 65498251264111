.resizerImages {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}

.resizerImageContainer {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  position: relative;
}

.resizerImage {
  height: 125px;
  width: 125px;
  object-fit: cover;
  cursor: pointer;
}

.resizerImageFinishMark {
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  border: 1px solid gray;
  padding: 2px;
}

.resizerImageResult {
  display: flex;
  justify-content: space-between;
}