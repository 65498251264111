.resizerResizeControl {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  padding-bottom: 1rem;
}

.resizerResizePreview {
  max-height: 200px; 
  max-width: 50%;
}

.resizerResizeInputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}

.resizerResizeResizeButton {
  padding-bottom: 2rem;
}

.resizerResizeFileControllButtons {
  display: flex;
  gap: 1rem;
  padding-bottom: 2rem;
}

.resizerResizeLoginButtons {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
}

@media (min-width: 480px) {
  .resizerResizeControl {
    flex-direction: row;
    align-items: end;
  }
}