
.fileUploadModalContent {
  color: var(--color-gray-900);
}

.uploadEditFile {
  display: flex;
  gap: 1.5rem;
  /* align-items: center; */
  flex-direction: column;
  padding: 1.5rem 1rem 3rem 1rem;
}

.uploadEditFileSection {
  padding-bottom: 1.5rem;
}

.uploadEdiFileLabel {
  padding-bottom: 0.5rem;
  display: block;
}

.uploadEdiFileInput {
  width: 70%
}

.uploadEditAuthTitle {
  padding-bottom: 0.5rem;
}

.uploadEditAuthButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
/* @media (min-width: 768px) { 
  .uploadEditFile {

  }
} */