.bucketObjectsViewSelect {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.bucketObjectsViewSelectButton {
  cursor: pointer;
}