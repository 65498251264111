.objectFolderItem {
  position: relative;
  height: 100px;
  width: 100px;
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  box-shadow: var(--shadow-medium);
  border: 0.25px solid gray;
  /* border-radius: 4px; */
}

.objectFolderItemFolderMark {
  position: absolute;
  top: 1px;
  left: 7.5px;
  font-size: 35px;
}

.objectFolderItemFolderName {
  position: absolute;
  /* bottom: 25px; */
  top: 40px;
  max-height: 35px;
  right: 2.5px;
  left: 2.5px;
  overflow: hidden;
  font-size: 16px;
}

.objectFolderItemImage {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.objectFolderItemCheck {
  position: absolute;
  top: 1px;
  right: 1px;
  /* bottom: 0px;
  left: 0px; */
  background-color: var(--background-color);
  font-size: 35px;
  opacity: 0.5;
}

.objectFolderItemAction {
  position: absolute;
  bottom: 1px;
  right: 5px;
  background-color: var(--background-color);
  font-size: 20px;
  /* font-size: 35px; */
  /* opacity: 0.5; */
}

.objectFolderItemBottomLeft {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

@media (min-width: 480px) {
  .objectFolderItemImage {
    height: 100px;
    width: auto;
    object-fit: unset;
  }
}