[data-theme='dark'] {
  // General

  // Theme
  --page-bg: #040404;
  --page-bg-light: #04040488;
  --page-text-color: #f9f9f9;
  --yellow-accent: #ffcc00;
  --yellow-accent-light: #ffcc0055;
  --link-color: var(--yellow-accent);
  --border-color: rgb(30, 30, 30);
  --border-color-light: rgba(102, 102, 102);
  --tooltip-bg: rgb(33, 33, 33);
  --tooltip-text-color: rgb(210, 210, 210);

  // Editor
  --editor-toolkit-bg: rgba(0, 0, 0, 0.5);
  --editor-options-bg: rgb(33, 33, 33);
  --options-text-color: var(--page-text-color);
  --editor-size-border-color: var(--yellow-accent);
  --editor-toolkit-panel-border: 1px solid rgb(100, 100, 120, 0.4);

  // Modal
  --modal-bg: var(--page-bg);
  --modal-text-color: var(--page-text-color);
  // --modal-hotkey-bg: rgb(60, 60, 90);
  --modal-hotkey-border-color: var(--page-text-color);
  --model-mask-bg: rgba(76, 76, 87, 0.4);

  // Text
  --text-color: white;
  --text-color-gray: rgb(195, 196, 198);
  --text-color-disabled: rgb(107, 111, 118);

  // Shared
  --btn-text-color: var(--text-color);
  --btn-text-hover-color: var(--page-bg);
  --btn-border-color: var(--yellow-accent);
  --btn-primary-hover-bg: var(--yellow-accent);
  --animation-pulsing-bg: rgb(240, 240, 255);

  // switch
  --switch-root-background-color: rgb(60, 63, 68);
  --switch-thumb-color: rgb(31, 32, 35);
  --switch-thumb-checked-color: white;

  --slider-background-color: var(--switch-root-background-color);

  // tooltip
  --tooltip-bg: hsl(197 6.8% 13.6%);

  // badge
  --badge-background-color: hsl(197 6.8% 13.6%);
  --badge-color: hsl(206 6% 63%);

  // keyboard shortcuts
  --box-shadow: inset 0 0.5px rgba(255, 255, 255, 0.1),
    inset 0 1px 5px hsl(195 7.1% 11%), 0px 0px 0px 0.5px hsl(207 5.6% 31.6%),
    0px 2px 1px -1px hsl(207 5.6% 31.6%), 0 1px hsl(207 5.6% 31.6%);

  --croper-bg: rgba(0, 0, 0, 0.5);

  // Tabs
  --tabs-active-color: rgb(39 40 49);
}
