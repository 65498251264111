.setting-block {
  display: flex;
  flex-direction: column;

  .option-desc {
    color: var(--text-color-gray);
    margin-top: 12px;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .sub-setting-block {
      color: var(--text-color);
    }

    svg {
      color: var(--text-color-gray);
    }
  }
}

.setting-block-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12rem;
}

.setting-block-content-v {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.setting-block-content-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.setting-block-desc {
  font-size: 1rem;
  margin-top: 8px;
  color: var(--text-color-gray);
}
