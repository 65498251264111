.select-trigger {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  height: 32px;
  gap: 8px;
  padding: 0 0.8rem;
  border: 1px solid var(--border-color);
  background-color: var(--page-bg);
  color: var(--options-text-color);

  svg {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
  }

  &:hover {
    border-color: var(--yellow-accent);
  }

  // &:focus-visible {
  //   border-color: var(--yellow-accent);
  // }

  &:disabled {
    color: var(--border-color);
    border-color: var(--border-color);
  }
}

.select-content {
  overflow: hidden;
  background-color: var(--page-bg);
  border-radius: 0.5rem;
}

.select-viewport {
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 5px;
}

.select-item {
  all: unset;
  background-color: var(--page-bg);
  color: var(--options-text-color);
  display: flex;
  align-items: center;
  border-radius: 0.5rem;

  padding: 6px 6px 6px 25px;
  position: relative;
  user-select: none;

  &:focus {
    color: var(--btn-text-hover-color);
    background-color: var(--yellow-accent);
  }
}

.select-item-indicator {
  position: absolute;
  left: 0;
  width: 25px;
  padding-right: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
