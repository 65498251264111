.prompt-wrapper {
  display: flex;
  gap: 12px;
}

.prompt-wrapper input {
  all: unset;
  border-width: 0;
  border-radius: 0.5rem;
  min-width: 600px;
  padding: 0 0.8rem;
  outline: 1px solid var(--border-color);

  &:focus-visible {
    border-width: 0;
    outline: 1px solid var(--yellow-accent);
  }
}
