.number-input {
  all: unset;
  flex: 1 0 auto;
  border-radius: 0.5rem;
  padding: 0 0.8rem;
  outline: 1px solid var(--border-color);
  height: 32px;
  text-align: right;

  &:focus-visible {
    outline: 1px solid var(--yellow-accent);
  }

  &:disabled {
    color: var(--border-color);
  }
}
