.imageSize {
  background-color: var(--page-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px 8px;
  z-index: 4;
}

.imageSizeExceed {
  color: red;
}