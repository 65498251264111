.modal-mask {
  position: fixed;
  z-index: 9998;
  inset: 0;
  background-color: var(--model-mask-bg);
  backdrop-filter: blur(12px);

  @media (prefers-reduced-motion: no-preference) {
    animation: opacityReveal 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}

@keyframes contentShow {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.modal {
  background-color: var(--page-bg);
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  place-self: center;
  padding: 25px;
  border-radius: 0.95rem;

  &:focus {
    outline: none;
  }

  .modal-header {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;

    .btn-primary {
      justify-self: end;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}
