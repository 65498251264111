.convertImages {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}

.convertImageContainer {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  position: relative;
}

.convertImage {
  height: 100px;
  width: 100px;
  object-fit: cover;
  cursor: pointer;
}

.convertImageFinishMark {
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  border: 1px solid gray;
  padding: 2px;
}

.convertImageResult {
  display: flex;
  justify-content: space-between;
}