@use '../../styles/Mixins/' as *;

.modal-shortcuts {
  grid-area: main-content;
  background-color: var(--modal-bg);
  color: var(--modal-text-color);
  box-shadow: 0px 0px 20px rgb(0, 0, 40, 0.2);

  @include mobile {
    display: grid;
    width: 100%;
    height: auto;
    animation: slideDown 0.2s ease-out;
  }
}

.shortcut-options {
  display: flex;
  gap: 48px;
  flex-direction: row;

  .shortcut-option {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 2rem;
    align-items: center;

    @include mobile {
      column-gap: 0;
      row-gap: 0.6rem;
    }
  }

  .shortcut-key {
    justify-self: end;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: max-content;
    border-radius: 6px;
    user-select: none;
    white-space: nowrap;

    color: var(--modal-text-color);
    background-color: var(--page-bg);

    box-sizing: border-box;
    box-shadow: var(--box-shadow);

    text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;

    @include mobile {
      padding: 0.2rem 0.4rem;
    }
  }

  .shortcut-description {
    justify-self: start;
    text-align: left;
    font-size: 0.95rem;

    @include mobile {
      text-align: left;
      width: auto;
      justify-self: start;
    }
  }
}

.shortcut-options-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 320px;
}
