.model-desc-link {
  // background-color: var(--badge-background-color);
  display: flex;
  justify-items: center;
  color: var(--badge-color);

  padding-left: 5px;
  padding-right: 5px;
  border-radius: 999px;
  text-decoration: none;
}
