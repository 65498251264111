.imageConvert {
  width: 100%;
  max-width: 40rem;
  padding: 1rem;
  margin: 0 auto;
}

.imageConvertElement {
  padding-bottom: 1rem;
}

.imageConvertrPreview {
  max-height: 200px; 
  max-width: 100%;
}


.imageConvertInputButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.imageConvertParamElements {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.imageConvertParamElement {
  padding-bottom: 1rem;
}

.imageConvertParamSelect {
  padding: 0.5rem;
}

.convertFileControllButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
}