@use '../../styles/Mixins/' as *;
@import './SettingBlock.scss';
@import './HDSettingBlock.scss';
@import './ModelSettingBlock.scss';

.modal-setting {
  background-color: var(--modal-bg);
  color: var(--modal-text-color);
  box-shadow: 0px 0px 20px rgb(0, 0, 40, 0.2);
  width: 680px;

  @include mobile {
    display: grid;
    width: 100%;
    height: auto;
    margin-top: -11rem;
    animation: slideDown 0.2s ease-out;
  }
}
.folder-path-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.folder-path {
  width: 95%;
  border-width: 0;
  border-radius: 6px;
  padding: 0.3rem 0.5rem;
  outline: 1px solid var(--border-color);

  &:focus-visible {
    border-width: 0;
    outline: 1px solid var(--yellow-accent);
  }
}
