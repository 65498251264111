.toast-viewpoint {
  position: fixed;
  bottom: 48px;
  right: 1.5rem;
  display: flex;
  flex-direction: row;
  padding: 25px;
  gap: 10px;
  max-width: 100vw;
  margin: 0;
  z-index: 999999;

  &:focus-visible {
    outline: none;
  }
}

.toast-root {
  border: 1px solid var(--border-color-light);
  background-color: var(--page-bg);
  border-radius: 0.6rem;
  padding: 15px;
  display: flex;
  align-items: center;
  max-width: 400px;
  cursor: pointer;

  gap: 12px;

  &[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='close'] {
    animation: opacityReveal 100ms ease-in forwards;
  }

  &[data-state='cancel'] {
    transform: translateX(0);
    animation: transform 100ms ease-out;
  }

  &.error {
    border: 1px solid var(--error-color);
  }

  &.success {
    border: 1px solid var(--success-color);
  }
}

.error-icon {
  height: 24px;
  width: 24px;
  color: var(--error-color);
}

.success-icon {
  height: 24px;
  width: 24px;
  color: var(--success-color);
}

.loading-icon {
  display: flex;
  align-items: center;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  transform-origin: center center;
  animation-timing-function: linear;
}

.toast-icon {
  display: flex;
  align-items: center;
}

.toast-desc {
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--text-color);
  min-width: 240px;
}
