header {
  height: 60px;
  padding: 1rem 1.5rem;
  // position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 20;
  backdrop-filter: blur(12px);
  border-bottom: 1px solid rgb(100, 100, 120, 0.2);
  background-color: var(--page-bg-light);
}

.shortcuts {
  z-index: 1;
}

.header-icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  justify-self: end;
}

.header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  justify-self: end;
}

.mask-preview {
  max-height: 400px;
  max-width: 400px;
  margin-top: 30px;
  margin-left: 20px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
