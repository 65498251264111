.topLevelImageList {
  display: flex; 
  flex-wrap: wrap;
}

.topLevelImageListList {
  margin-top: -4px;
}

.topLevelFolderList {
  display: flex; 
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.topLevelListSection {
  padding-bottom: 2rem;
}



.objectTableView {
  min-height: 100vh;
}

.objectTableContainer {
  display: flex;
  margin-top: 1rem;
}

.objectTable {
  table-layout: fixed;
  /* width: 100%; */
  width: 98%;
  margin: 1rem auto 1rem auto;
  border-collapse: collapse;
  border: 1px solid var(--color-gray-200);
}

.objectTable thead th:nth-child(1) {
  width: 1rem;
}

.objectTable thead th:nth-child(2) {
  /* width: 10rem; */
  width: 50%;
}

.objectTable thead th:nth-child(3) {
  width: 4rem;
}

.objectTable thead th:nth-child(4) {
  width: 4rem;
}

/* .objectTable thead th:nth-child(5) {
} */

.objectTable th, td {
  padding: 0.5rem;
}

.objectTable thead {
  background-color: var(--color-gray-25);
  border-bottom: 1px solid var(--color-gray-200);
}

/* .objectTable tbody tr:nth-child(even) {
  background-color: var(--color-gray-10);
} */

/* .objectTable tbody tr:nth-child(odd) {
  background-color: #e495e4;
} */

.objectTable tbody tr {
  border-top: 1px solid var(--color-gray-100);
}

.objectTableMenu {
  display: none;
}

.objectTableObjectName {
  word-break: break-all;
}

.objectImageListSlide {
  padding-bottom: 1rem;
}

.objectImageListSlideTitle {
  padding-left: 1rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .objectTable {
    table-layout: unset;
    margin: 0 1rem 0 0;
  }

  .objectTable thead th:nth-child(3) {
    width: 8rem;
  }
  
  .objectTable thead th:nth-child(4) {
    width: 7rem;
  }

  .objectTable thead th:nth-child(5) {
    width: 18rem;
  }

  .objectTableMenu {
    display: block;
    /* border: 0.5px solid gray; */
    width: 15rem;
    padding-left: 0.25rem;
    color: var(--color-gray-700);
  }
}

@media (min-width: 480px) {
  .topLevelImageList {
    padding: 1rem;
    gap: 1rem;
  }
}