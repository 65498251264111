/* .aboutDrawDreamerImageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
} */

.aboutDrawDreamerImage {
  max-height: 200px;
  max-width: 100%;
}

.aboutDrawDreamerMoreImage {
  height: 125px;
  max-width: 100%;
}

.aboutDrawDreamerImages {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.aboutDrawDreamerRefinerImage {
  height: 200px;
  max-width: 100%;
}