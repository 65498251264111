@use '../../styles/Mixins/' as *;

.side-panel {
  position: absolute;
  top: 68px;
  right: 1.5rem;
  padding: 0.1rem 0.3rem;
  z-index: 4;

  border-radius: 0.8rem;
  border-style: solid;
  border-color: var(--border-color);
  border-width: 1px;
  background-color: var(--page-bg);
}

.side-panel-trigger {
  font-size: 16px;
  border: 0px;
}

.side-panel-content {
  outline: none;
  position: relative;
  font-size: 14px;
  top: 8px;
  right: 1.5rem;
  padding: 1rem 1rem;
  z-index: 9;

  // backdrop-filter: blur(12px);
  color: var(--text-color);
  background-color: var(--page-bg);

  border-radius: 0.8rem;
  border-style: solid;
  border-color: var(--border-color);
  border-width: 1px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  .setting-block-content {
    gap: 1rem;
  }

  // input {
  //   height: 24px;
  //   // border-radius: 4px;
  // }

  // button {
  //   height: 28px;
  //   // border-radius: 4px;
  // }
}

.negative-prompt {
  all: unset;
  border-width: 0;
  border-radius: 0.5rem;
  min-height: 150px;
  max-width: 200px;
  width: 100%;
  padding: 12px 0.8rem;
  outline: 1px solid var(--border-color);

  &:focus-visible {
    border-width: 0;
    outline: 1px solid var(--yellow-accent);
  }

  &:-webkit-input-placeholder {
    padding-top: 10px;
  }

  &:-moz-input-placeholder {
    padding-top: 10px;
  }

  &:-ms-input-placeholder {
    padding-top: 10px;
  }
}

.resize-title-tile {
  width: 86px;
  font-size: 0.5rem;
  color: var(--text-color-gray);
}
