:root {
  // General

  // Theme
  --page-bg: rgb(255, 255, 255);
  --page-bg-light: rgb(255, 255, 255, 0.5);
  --page-text-color: #040404;
  --yellow-accent: #ffcc00;
  --yellow-accent-light: #ffcc0055;
  --link-color: rgb(0, 0, 0);
  --border-color: rgb(239, 241, 244);
  --border-color-light: rgba(100, 100, 120, 0.5);
  --tooltip-bg: rgb(230, 230, 234);
  --tooltip-text-color: rgb(0, 0, 0);

  --error-color: rgb(239, 68, 68);
  --success-color: rgb(16, 185, 129);

  // Editor
  --editor-toolkit-bg: rgba(255, 255, 255, 0.5);
  --editor-options-bg: rgb(230, 230, 234);
  --options-text-color: var(--page-text-color);
  --editor-size-border-color: var(--border-color);
  --editor-toolkit-panel-border: 0;

  // Modal
  --modal-bg: var(--page-bg);
  --modal-text-color: rgb(0, 0, 0);
  --modal-hotkey-border-color: rgb(0, 0, 0);
  --model-mask-bg: rgba(209, 213, 219, 0.4);

  // Text
  --text-color: #040404;
  --text-color-gray: rgb(107, 111, 118);
  --text-color-disabled: rgb(107, 111, 118);

  // Shared
  --btn-text-color: var(--text-color);
  --btn-text-hover-color: #040404;
  --btn-border-color: rgb(100, 100, 120);
  --btn-primary-hover-bg: var(--yellow-accent);
  --animation-pulsing-bg: rgb(255, 255, 255, 0.5);

  // switch
  --switch-root-background-color: rgb(223, 225, 228);
  --switch-thumb-color: var(--page-bg);
  --switch-thumb-checked-color: var(--page-bg);

  --slider-background-color: var(--switch-root-background-color);

  // tooltip
  --tooltip-bg: var(--page-bg);

  // badge
  --badge-background-color: hsl(209 13.3% 95.3%);
  --badge-color: hsl(206 6% 43.5%);

  // keyboard shortcuts
  --box-shadow: inset 0 0.5px rgba(255, 255, 255, 0.1),
    inset 0 1px 5px hsl(210 16.7% 97.6%), 0px 0px 0px 0.5px hsl(205 10.7% 78%),
    0px 2px 1px -1px hsl(205 10.7% 78%), 0 1px hsl(205 10.7% 78%);

  --croper-bg: rgba(0, 0, 0, 0.5);

  // Tabs
  --tabs-active-color: rgb(240 243 249);
}
