@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

.file-manager-modal {
  color: var(--text-color);
  height: 90%;
  width: 80%;
}

.react-photo-album.react-photo-album--columns {
  height: 80vh;
}

.react-photo-album--photo {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;
  border: 1px solid transparent;

  // transform-origin: 0 0;
  transition: transform 0.25s, visibility 0.25s ease-in;

  &:hover {
    border: 1px solid var(--border-color);
    transform: scale(1.03);
  }
}

.ScrollAreaRoot {
  border-radius: 4px;
  overflow: hidden;
  // box-shadow: 0 2px 10px var(--blackA7);
  --scrollbar-size: 10px;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ScrollAreaScrollbar {
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  padding: 2px;
  // background: var(--blackA6);
  transition: background 160ms ease-out;
}

.ScrollAreaScrollbar:hover {
  background: var(--blackA8);
}
.ScrollAreaScrollbar[data-orientation='vertical'] {
  width: var(--scrollbar-size);
}
.ScrollAreaScrollbar[data-orientation='horizontal'] {
  flex-direction: column;
  height: var(--scrollbar-size);
}

.ScrollAreaThumb {
  flex: 1;
  background: var(--mauve10);
  border-radius: var(--scrollbar-size);
  position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 44px;
  min-height: 44px;
}

.ScrollAreaCorner {
  background: var(--blackA8);
}

.file-search-input {
  width: 250px;
  padding-left: 30px;
  height: 32px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
}

.sort-btn-inactive {
  svg {
    opacity: 0.5;
  }
}

/* reset */
// button,
// fieldset,
// input {
//   all: unset;
// }

.TabsRoot {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--page-bg);
  align-self: flex-start;
}

.TabsList {
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: flex-start;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  background-color: var(--page-bg);
  padding: 4px;
}

.TabsTrigger {
  font-family: inherit;
  background-color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  line-height: 1;
  color: var(--modal-text-color);
  user-select: none;
  background-color: var(--page-bg);
  border-radius: 8px;
}
.TabsTrigger:hover {
  background-color: var(--tabs-active-color);
}
.TabsTrigger[data-state='active'] {
  background-color: var(--tabs-active-color);
}
.TabsTrigger:focus {
  position: relative;
}

.TabsContent {
  background-color: white;
  outline: none;
  background-color: var(--page-bg);
  width: 100%;
}

.TabsContent[data-state='active'] {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
