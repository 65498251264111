.container {
  /* width: 500px; */
  border: 1px solid #333;
  padding: 10px 4px;

  display: flex;
  justify-content: center;
  gap: 1.5rem;
  /* background-color: #1e1e1e; */
  background-color: #282828;
  /* background-color: lightblue; */
}

.upload-container {
  /* width: 500px; */
  /* border: 1px solid #333; */
  padding: 4px 4px 12px 0;

  display: flex;
  justify-content: center;
  gap: 2rem;
  /* background-color: #1e1e1e; */
  background-color: #282828;
  background-color: gray;
}

.select-wrapper {
  /* background: url(http://s10.postimg.org/4rc0fv8jt/camera.png) no-repeat; */
  background: url('./img/openImage.png');
  background-size: cover;
  display: block;
  position: relative;
  width: 33px;
  height: 26px;
  cursor: pointer;
}
.select-wrapper2 {
  /* background: url(http://s10.postimg.org/4rc0fv8jt/camera.png) no-repeat; */
  background: url('./img/undo.png');
  background-size: cover;
  display: block;
  position: relative;
  width: 33px;
  height: 26px;
  cursor: pointer;
}

.select-wrapper3 {
  /* background: url(http://s10.postimg.org/4rc0fv8jt/camera.png) no-repeat; */
  background: url('./img/redo.png');
  background-size: cover;
  display: block;
  position: relative;
  width: 33px;
  height: 26px;
  cursor: pointer;
}

.select-wrapper4 {
  /* background: url(http://s10.postimg.org/4rc0fv8jt/camera.png) no-repeat; */
  background: url('./img/download.png');
  background-size: cover;
  display: block;
  position: relative;
  width: 33px;
  height: 26px;
  cursor: pointer;
}

#image_src {
  width: 26px;
  height: 26px;
  margin-right: 100px;
  opacity: 0;
  filter: alpha(opacity=0); /* IE 5-7 */
}