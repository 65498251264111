.fullSizeContainer {
  width:100vw; 
  height:100vh;
  background: black; 
  color: gainsboro;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; 
  overflow: auto;
}

.fullSizeImageContainer {
  text-align: center;
  padding-top: 1rem;
}

.fullSizeImageClose {
  position: fixed;
  top: 1rem;
  right: 2rem;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
}

.fullSizeImageFit {
  position: fixed;
  top: 1rem;
  left: 2rem;
  font-size: 1.25rem;
  /* font-weight: bolder; */
  cursor: pointer;
}