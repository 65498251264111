// @use '~inter-ui/default' with (
//   $inter-font-display: swap,
//   $inter-font-path: '~inter-ui/Inter (web)'
// );
// @use '~inter-ui/variable' with (
//   $inter-font-display: swap,
//   $inter-font-path: '~inter-ui/Inter (web)'
// );

// General
@use './Colors';
@use './ColorsDark';
@use './Animations';

// App
@use './App';
@use '../components/Editor/Editor';
@use '../components/FileManager/FileManager';
@use '../components/LandingPage/LandingPage';
@use '../components/Header/Header';
@use '../components/ImageSize/ImageSize.scss';
@use '../components/Header/PromptInput';
@use '../components/Header/ThemeChanger';
@use '../components/Shortcuts/Shortcuts';
@use '../components/Settings/Settings.scss';
@use '../components/SidePanel/SidePanel.scss';
@use '../components/Plugins/Plugins.scss';
@use '../components/Croper/Croper.scss';
@use '../components/InteractiveSeg/InteractiveSeg.scss';
@use '../components/SDProgress/SDProgress.scss';

// Shared
@use '../components/FileSelect/FileSelect';
@use '../components/shared/Button';
@use '../components/shared/Modal';
@use '../components/shared/Selector';
@use '../components/shared/Switch';
@use '../components/shared/NumberInput';
@use '../components/shared/Toast';
@use '../components/shared/Tooltip';

// Main CSS
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// @include default.all;
// @include variable.all;

html {
  font-family: 'Inter', 'system-ui';
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', 'system-ui';
  }
}
