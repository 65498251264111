.mainNav {
  /* position:fixed; */
  top: 0; 
  width: 100%; 
  height: 3rem;
  /* background: var(--color-gray-100); */
  background-color: whitesmoke;
  background-color: rgb(250, 255, 255);
  box-shadow: var(--shadow-medium);
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.mainNavTitle {
  margin: auto 0;
}