.aboutIopaintSection {
  font-size: 1.1rem;
  padding-bottom: 1rem;
}

.aboutIopaintTitle {
  text-align: center;
  padding-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.aboutIopaintImageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
}

.aboutIopaintImage {
  max-height: 200px;
  max-width: 100%;
}

.aboutIopaintMoreImage {
  height: 125px;
  max-width: 100%;
}
/* .uploadImageImage {
  max-height: 300px; 
  max-width: 100%;
} */