.ProgressWrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 68px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  border: 1px solid var(--border-color);
  border-radius: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: var(--page-bg);
  border-radius: 99999px;
  width: 130px;
  height: 10px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.ProgressIndicator {
  background-color: var(--yellow-accent);
  width: 100%;
  height: 100%;
  transition: transform 60ms cubic-bezier(0.65, 0, 0.35, 1);
}
